.modal1 {
    background-color: #151515;
    position: relative;
    color: white;
    font-family: 'Fugaz One', sans-serif;
    font-size: 1rem;

    min-width: 50%;
    max-width: 80%;

    .bt-exit {
        position: absolute;
        top: 2px;
        right: 2px;
    }

    .bt-close {
        color: white;
        background: linear-gradient(180deg, #206E8D 0%, #0B2E3D 100%);
        border: 1px solid #02323D
    }
}
