.home .home4 {
    /* background-color: black; */
}

.home .home4 .bg {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 54.17vw;
    max-height: 1000px;
}

.home .home4 .bg img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.home .home4 .front {
    position: relative;
    width: 100%;
    height: 54.17vw;
    max-height: 1000px;
    /* background-color: green; */
}

.home .home4 .frontxxl {
    /* background-color: blue; */
    margin-top: 1.5% !important;
}

.home .home4 .charview {
    z-index: 1;
    margin-left: 2% !important;
}

.home .home4 .char-pose {
    object-fit: contain;
    width: 26vw;
    height: 38.67vw;
}

.home .home4 .btn-pose img {
    cursor: pointer;
    filter: grayscale(100%);
    /* max-width: 500px; */
    /* max-height: 800px; */
}

.home .home4 .btn-pose img.clicked {
    filter: grayscale(0%);
}

.home .home4 .pig-rect-top {
    width: 600px;
    height: 400px;
    background-color: red;
    clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 20% 100%);
}

.home .home4 .charsel-xxl {
    position: relative;
    height: auto;
    width: 85%;
    padding-right: 7%;
    /* padding: 1%; */
    /* padding-top: 1.5%; */
    /* background-color: red; */
}

.home .home4 .charsel-xxl .info-context-wrapper {
    position: relative;
    /* width: 0; */
    /* margin-top: 0%; */
    /* margin-right: 10%; */
}

.home .home4 .charsel-xxl .charinfobg {
    position: relative;
    width: 100%;
}

.home .home4 .charsel-xxl .info-context-wrapper .props {
    object-fit: contain;
}

.home .home4 .charsel-xxl .info-context-wrapper .props.logo {
    position: absolute;
    right: -1%;
    top: -7%;
    width: 15%;
}

.home .home4 .charsel-xxl .info-context-wrapper .props.drone {
    position: absolute;
    left: -7%;
    top: -15%;
    width: 15%;
}

.home .home4 .charsel-xxl .characters{
    width: 100%;
    /* margin-left: 4.5%; */
    cursor: pointer;
}

.home .home4 .charsel-xxl .charinfo-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #00ff0088; */
}

/* .home .home4 .info-context {
    position: absolute;
    top: 0%;
    left: 7%;
    width: 75%;
    height: 47.5%;
} */

.home .home4 .info-context .title {
    color: #FFC121;
}

.home .home4 .info-context .rank {
    object-fit: contain !important;
    max-width: max(6%, 36px) !important;
    /* background-color: RED; */
}

.home .home4 .info-context .context {
    text-align: start;
}

.home .home4 .info-context .ytag {
    width: 10px;
    height: auto;
    background-color: #FFC121;
}

.home .home4 .character {
    position: relative;
    object-fit: contain;
    margin-top: 2.5%;
    width: 23.5%;
}

.home .home4 .character img {
    filter: grayscale(100%);
}

.home .home4 .character img.selected {
    filter: grayscale(0%);
}

.home .home4 .char-rank {
    position: absolute;
    object-fit: contain;
    width: 17%;
    top: 7%;
    right: 7%;
    filter: grayscale(0%) !important;
}

@media (max-width: 1199.98px) {
    .home .home4 .bg {
        height: calc(min(145vw, 740px) + max(40vw, 160px) );
        object-fit: fill;
        width: auto;
        /* height: 100%; */
        max-height: 960px;
    }

    .home .home4 .front {
        height: calc(min(145vw, 740px) + max(40vw, 160px) );
        max-height: 960px;
        /* background-color: red; */
    }

    .home .home4 .char-pose {
        height: min(120vw, 658px);
        width: 80%;
    }

    .home .home4 .btn-pose {
        max-width: 20vw;
        max-height: 15vw;
    }

    .home .home4 .image-gallery-left-nav {
        position: absolute;
        width: max(min(15vw, 80px), 50px);
        left: calc(50% - max(min(10vw, 80px), 40px) - 1vw);
        top: max(-22.5vw, -110px);
        left: 1vw;
    }

    .home .home4 .image-gallery-right-nav {
        position: absolute;
        width: max(min(15vw, 80px), 50px);
        right: calc(50% - max(min(10vw, 80px), 40px) - 1vw);
        top: max(-22.5vw, -110px);
        right: 1vw;
    }

    .home .home4 .info-bg {
        background: #180013;
    }

    .home .home4 .info-context {
        position: relative;
        left:auto;
        width: 100%;
    }

    .home .home4 .status-context {
        margin-top: 3vw !important;
    }
}



.home .home4 .image-gallery-slide-wrapper {
    position: relative;
    height: 0px;
    /* background-color: red; */
}

.home .home4 .image-gallery-thumbnail {
    /* margin-left: max(min(1vw, 10px), 2px); */
    padding: 0px;
    width: 40vw;
    min-height: 170px !important;
    height: 50vw;
    max-width: 160px;
    max-height: 184px;
    /* background-color: blue; */
    box-sizing: none !important;
    border: none !important;
}

.home .home4 .image-gallery .item {
    position: relative;
    width: 100%;
    height: auto;
    color: white;
    /* background-color: green; */
    box-sizing: none !important;
    border: none !important;
}

.home .home4 .image-gallery .item img {
    object-fit: contain;
    /* max-height: 30vw !important; */
    filter: grayscale(100%);
}

.home .home4 .image-gallery .item img.selected {
    filter: grayscale(0%);
}

.home .home4 .image-gallery .item .map img {
    object-fit:cover;
    height: 100%;
}

.home .home4 .front {
    /* background-color: #0000ff44; */
}