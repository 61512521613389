.ticket{
    position: relative;
    min-height: 70vh;
    color: black;
}

.ticket .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    filter: blur(5px) brightness(30%);
}

.ticket .bg img {
    object-fit: cover;
}

.ticket .form-container {
    margin-top: 64px;
}

.ticket .form-logo {
    height: 64px;
}

.ticket .form-logo img{
    width: auto;
    height: 100%;
}



.ticket .loader {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: #00000044;
}

.ticket .loader .hearts {
    position: absolute;
    object-fit: contain;
    width: 64px;
    height: 64px;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
}

.ticket .form {
    position: relative;
    background-color: #f2f3f4ff;
    border-radius: 5px;
    align-items: start;
    text-shadow: 1px 1px #ffffff88;
    box-shadow: 2px 2px 2px #00000010;
}



.ticket .form p {
    text-align: start !important;
    align-items: start !important;
    align-content: start !important;
}

.ticket .form .desc {
    font-size: 0.7rem;
    color: #999999;
}

.required:after {
    content:" *";
    color: red;
}

.ticket .form input {
    background-color:#bec0c2;
    border: none;
    border-radius: 5px;
    padding: 0.3rem;
    padding-left: 0.5rem;
    /* min-width: 8rem; */
    width: 25rem;
    max-width: 100%;
    
}

.ticket .form input:focus  {
    outline: 1.5px solid #ff8e0f !important;
}


.ticket .form textarea {
    position: relative;
    background-color:#bec0c2;
    border: none;
    border-radius: 5px;
    padding: 0.3rem;
    padding-left: 0.5rem;
    /* min-width: 8rem; */
    width: 100%;
    /* max-width: 80% !important; */
    min-height: 20rem;
}

.ticket .form textarea:focus {
    outline: 1.5px solid #ff8e0f !important;
}

.ticket .form button {
    border: none;
    border-radius: 5px;
    background-color: #1b8afa;
    color: white;
    padding: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 100%;
}

.ticket .form .error {
    font-size: 0.8rem;
    color: red;
}

.ticket .response {
    position: relative;
    background-color: #f2f3f4dd;
    border-radius: 5px;
    align-items: start;
    text-align: start;
    min-height: 70vh;
    box-shadow: 2px 2px 2px #00000010;
}

.ticket .response .context {
    z-index: 1;
    margin-left: 7%;
    margin-right: 30%;
    color: #222222;
    text-shadow: 1px 1px #ffffff88;
    /* background-color: #ff000055; */

    transition: all 0.6s ease-in-out;
}

.ticket .response .header {
    height: 40px;
    /* width: 50px; */
}

.ticket .response .header img {
    width: auto;
    height: 100%;
}

.ticket .response .img-bg {
    position: absolute;
    right: 2px;
    bottom: 0px;
    object-fit: contain;
    width: auto;
    height: 30%;
    transition: all 0.6s ease-in-out;
}

@media (orientation: portrait) {
    .ticket .response .context {
        margin-top: 1vw !important;
        margin-left: 1vw;
        margin-right: 1vw;
    }
}