.home .footer {
    background: #0A0A0A;
}

.home .footer .copyright {
    /* font-family: Rubik; */
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.home .footer .sc_icon {
    max-width: 48px;
    max-width: 48px;
}

.home .footer .line {
    columns: white;
    width: 80%;
}