$layout-color: false;

@mixin test-layout($color){
    @if $layout-color {
        background-color: $color;
    }
}

.right-panel {
    @include test-layout(#ffffff44);
    width: 100% !important;
    overflow: hidden !important;

    .panel-top {
        @include test-layout(#ffff0044);
        min-height: 20%;
    }

    .panel-bullet {
        @include test-layout(#00ff0044);
        position: relative;
        overflow: hidden !important;
        width: 100%;
        
        min-height: 35%;
        max-width: 300px;
        transform: rotate(-5deg) translate(5%, 0%);

        .bullet-area {
            position: relative;
            align-items: start;
            align-content: start;
            max-height: 45%;
            .bullet-bg {
                @include test-layout(#ff000044);
                position: relative;
                width: 100%;
                transform: translate(-0%, 20%);

                &.free{
                    transform: translate(10%, 20%);
                }
            }
        }

        .bullet-label {
            text-align: start;
            text-shadow: 
                -1px -1px 0 black, /* Top-left shadow */
                1px -1px 0 black,  /* Top-right shadow */
                -1px 1px 0 black,  /* Bottom-left shadow */
                1px 1px 0 black;   /* Bottom-right shadow */
            font-family: 'Fugaz One', sans-serif;
            font-size: 2.5vh;
            font-weight: 400;

            &.free {
                transform: translate(15%, 0%);
            }

            &.cash {
                transform: translate(5%, 0%);
            }
        }

        .reset-label {
            position: absolute;
            color: #FEEC3D;
            font-family: Fugaz One;
            font-size: 8px;
            font-weight: 400;
            -webkit-text-stroke: 0.5px black;
            right: -5%;
            transform: translate(0%, 3%);
        }

        .bullet-count {
            position: absolute;
            font-family: 'Fugaz One', sans-serif;
            font-size: 18px;
            font-weight: 400;
            -webkit-text-stroke: 1px black;

            &.free {
                top: 36%;
                right: 33%;
            }

            &.cash {
                top: 38%;
                right: 45%;
            }

            &.bullet-deduct {
                animation: colorChange 0.6s ease-in-out;
            }
        }
        
    }

    .panel-bt {

        .bt-shoot {
            visibility: hidden;

            &.showed{
                visibility: visible;
            }
        }

        .bt-continue {
            visibility: visible;
            opacity: 1;
            filter: grayscale(0%);
            transition: opacity 0.3s ease;
            cursor: pointer;
        
            &.waiting {
                opacity: 0;
                visibility: hidden;
                filter: grayscale(100%);
            }
        }
    }
}

@media (min-width: 768px) {
    .right-panel {
        .panel-bullet {
            .bullet-count {
                font-size: 26px;

                &.free {
                    top: 45%;
                    right: 33%;
                }
    
                &.cash {
                    top: 47%;
                    right: 45%;
                }
            }
        }
    }
}

@keyframes colorChange {
    0% {
        color: white;
    }
    50% {
        color: orange;
    }
    100% {
        color: white;
    }
}