.evsample {
    background-color: #2e4452;
    min-width: 768px;
    min-height: 1160px;
}

.evsample .bg {
    margin-top: -10%;
}

.evsample .context {
    position: absolute;
    top: 10px;
    width: 100%;
    height: auto;
}

.evsample .hdtext1 {
    font-size: 32px;
    font-weight: bold;
    text-shadow: 
        2px 2px 2px black,
        -2px -2px 2px black,
        2px -2px 2px black,
        -2px 2px 2px black,
        2px 2px 4px red,
        -2px -2px 4px red,
        2px -2px 4px red,
        -2px 2px 4px red
}

.evsample .hdtext2 {
    font-size: 16px;
    font-weight: bold;
    text-shadow: 
        2px 2px 2px black,
        -2px -2px 2px black,
        2px -2px 2px black,
        -2px 2px 2px black
}

.evsample .tb1 {
    background-color: #898991;
    max-width: 986px;
    min-width: 600px;
}

.evsample .tb1 .tb-text {
    font-size: 16px;
    font-weight: bold;
}

.evsample .item {
    position: relative;
    background-color: #131a22;
    width: 25%;
    max-width: 25%;
    min-height: 220px;
    min-width: 140px;
}

.evsample .item img{
    max-width: 60%;
}

.evsample .item .text1 {
    align-items: center;
    text-align: center;
    align-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #dab715;
    max-width: 90%;
}

.evsample .item .text2 {
    position: absolute;
    left: 10%;
    bottom: 50px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    width: 80%;
}

.evsample .item button{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -0%);
    bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    min-width: 100px;
    /* max-height: 40px; */
    transition: background-color 0.3s ease;
}

.evsample .item button:active {
    transform: translate(-50%, -0%) scale(0.98);
}


.evsample .item button.sg0 {
    /* background-color: #626760; */
}

.evsample .item button.sg1 {
    background-color: #f2c700;
}

.evsample .item button.sg2 {
    background-color: #62b720;
}

.evsample .footer {
    align-items: start;
    align-content: start;
    text-align: start;
    background-color: rgb(8, 6, 6);
}