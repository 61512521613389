.policies {
    background: linear-gradient(269.67deg, #13000F 0.27%, #29000D 46.36%, #12000D 99.71%);
    min-height: 100vh;
    text-align: start;
}

.policies.bg-blcack {
    background-color: black;
}

.policies .subheader h1 {
    color: #ffffff;
}

.policies .subheader .header-selected {
    color: #B32A20;
}

.policies .contexts {
    max-height: calc(100vh - max(1vw, 300px));
    /* max-height: 230px; */
    overflow: auto; /* Add a scroll bar when content overflows */
    overflow-y: scroll;
    background-color: black;
    margin: 1rem;
}


@media (max-width: 767.98px) {
    .policies .contexts {
        max-height: unset;
        overflow: visible;
        margin: unset;
    }
}

.policies .layer11 {
    position: fixed;
    z-index: 900;
    width: 100vw;
    
}

.policies .layer11.toggled {
    height: 100vh;
    background: linear-gradient(269.67deg, #13000F 0.27%, #29000D 46.36%, #12000D 99.71%);
    text-align: center;
}

.policies .layer11 .navtoggle {
    position: absolute;
    right: 1vw;
    top: 1vw;
    max-width: 10vw;
    border: none !important;
    box-sizing: none !important;
}

.policies .policies1 .copyright {
    position: absolute;
    bottom: 1px;
}




.policies .layer11 .header-item {
    font-size: max(min(2.1vw, 40px), 32px);
}


.policies .layer11 .props {
    position: absolute;
    object-fit: contain;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.policies .layer11 .prop01 {
    position: absolute;
    max-width: 60%;
    max-height: 60%;
    bottom: 0px;
    left: 0px;
}

.policies .layer11 .prop02 {
    position: absolute;
    max-width: 60%;
    max-height: 60%;
    bottom: 0px;
    right: 0px;
}

.policies .layer11 .prop03 {
    position: absolute;
    max-width: 20%;
    max-height: 20%;
    bottom: 55%;
    left: 0px;
}

.policies .layer11 .prop04 {
    position: absolute;
    max-width: 20%;
    max-height: 20%;
    bottom: 55%;
    right: 0px;
}