$layout-color: false;

@mixin test-layout($color){
    @if $layout-color {
        background-color: $color;
    }
}

.quest-progress-area {
    font-family: 'Fugaz One', sans-serif;
    @include test-layout(#00ffff44);
    overflow: hidden !important;

    .bar-bg {
        position: absolute;
        background-color: black;
        min-height: 2px;
        max-height: 25px;
        max-width: 100%;
        border: solid #525252 1px;
        clip-path: polygon(1% 0%, 100% 0%, 99% 100%, 0% 100%);
        z-index: 0;
    }

    .rewards {
        @include test-layout(#0000ff44);
    }

    .reward {
        z-index: 1;

        .reward-item {
            background: linear-gradient(180deg, #4A5C64 0%, #2C2C2C 100%);
            position: relative;
            border: solid #0C1D22 1px;

            .reward-text {
                position: absolute;
            }
        }

        .reward-point {
            color: #00FF38;
            font-family: 'Rubik Wet Paint', sans-serif;
        }
    }

    
}

@media (max-width: 767.98px){
    .quest-progress-area {
        width: 440px;

        .bar-bg {
            height: 6px;
            top: 60%;
            width: 440px;
        }

        .reward {
            .reward-item {
                height: 44px;
                width: 44px;

                .reward-text {
                    font-size: 10px;
                    right: 2px;
                    top: 22px;
                }
            }

            .reward-mark {
                width: 12px;
                height: 12px;
            }

            .reward-point {
                font-size: 12px;
                line-height: 18px;
                -webkit-text-stroke: 1px black;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px){
    .quest-progress-area {
        width: 560px;

        .bar-bg {
            height: 8px;
            top: 62%;
            width: 560px;
        }

        .reward {
            .reward-item {
                height: 52px;
                width: 52px;

                .reward-text {
                    font-size: 12px;
                    right: 2px;
                    top: 28px;
                }
            }

            .reward-mark {
                width: 14px;
                height: 14px;
            }

            .reward-point {
                font-size: 12px;
                line-height: 18px;
                -webkit-text-stroke: 1px black;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .quest-progress-area {
        width: 720px;

        .bar-bg {
            height: 10px;
            top: 66%;
            width: 720px;
        }

        .reward {
            .reward-item {
                height: 72px;
                width: 72px;

                .reward-text {
                    font-size: 12px;
                    right: 2px;
                    top: 48px;
                }
            }

            .reward-mark {
                width: 18px;
                height: 18px;
            }

            .reward-point {
                font-size: 18px;
                line-height: 20px;
                -webkit-text-stroke: 1px black;
            }
        }
    }
}

@media (min-width: 1200px) {
    .quest-progress-area {
        width: 960px;

        .bar-bg {
            height: 12px;
            top: 66%;
            width: 960px;
        }

        .reward {
            .reward-item {
                height: 96px;
                width: 96px;

                .reward-text {
                    font-size: 18px;
                    right: 4px;
                    top: 70px;
                }
            }

            .reward-mark {
                width: 28px;
                height: 28px;
            }

            .reward-point {
                font-size: 24px;
                line-height: 26px;
                -webkit-text-stroke: 2px black;
            }
        }
    }
}