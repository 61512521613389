.home .home5 {
    /* background-color: red; */
    position: relative;
}

.home .home5 .image-gallery-thumbnails-wrapper {
    position: relative;
    width: 33%;
    
    /* background-color: green; */

}

.home .home5 .image-gallery-thumbnail {
    position: relative;
    /* margin-left: max(min(1vw, 10px), 2px); */
    width: 100%;
    /* background-color: blue !important; */
    box-sizing: none !important;
    border: none !important;
}

.home .home5 .image-gallery-slide-wrapper {
    position: relative;
    /* background-color: red; */
    width: 65%;
    /* height: 33vw; */
    margin-bottom: 1vw;
}


.home .home5 .image-gallery .item {
    /* width: 66vw; */
    /* background-color: red !important; */
}

@media (max-width: 991.98px) {
    .home .home5 .image-gallery-slide-wrapper {
        width: 100%;
        /* height: 50vw; */
        margin-bottom: 0vw;
    }

    .home .home5 .image-gallery-thumbnails-wrapper {
        width: 100%;
    }

    .home .home5 .image-gallery-thumbnail {
        width: 38%;
    }
}


.home .home5 .image-gallery-left-nav {
    position: absolute;
    width: max(min(10vw, 80px), 40px);
    cursor: pointer;
    z-index: 10;
}

.home .home5 .image-gallery-right-nav {
    position: absolute;
    width: max(min(10vw, 80px), 40px);
    cursor: pointer;
    z-index: 10;
}