.policies .header {
    /* background-color: red; */
}

.policies .header .logo {
    width: 80%;
    max-width: 500px;
}

.policies .header .subheader {
    
}