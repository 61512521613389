.App {
  text-align: center;
  background-color: black;
  color: white;

  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 400;
  font-size: max(min(1.2vw, 20px), 16px);
  line-height: max(min(2.1vw, 36px), 28px);
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #61dafb; */
}
a {
  color: white;
  text-decoration: none;
}

.maxw-576, .maxw-sm{
  max-width: 576px;
}

.maxw-768, .maxw-md{
  max-width: 768px;
}

.maxw-992, .maxw-lg{
  max-width: 992px;
}

.maxw-1200, .maxw-xl{
  max-width: 1200px;
}

.maxw-1400, .maxw-xxl{
  max-width: 1400px;
}

.maxw-1920, .maxw-xxxl{
  max-width: 1920px;
}

h1 {
  font-family: 'Squada One', cursive;
  font-weight: 400;
  font-size: max(min(2.1vw, 40px), 24px);
  letter-spacing: -0.02em !important;
}

.tcontext {
  font-family: 'Squada One', cursive;
  font-weight: 400;
  font-size: max(min(1.1vw, 24px), 16px);
  letter-spacing: -0.02em !important;
}

.tcontexth2 {
  font-family: 'Squada One', cursive;
  font-weight: 400;
  font-size: max(min(2.1vw, 40px), 24px);
  letter-spacing: -0.02em !important;
}

.t-border-red {
  -webkit-text-stroke: min(0.1vw, 2px) #BF2C22; 
  text-stroke: min(0.1vw, 2px) #BF2C22;
}

.t-border-black {
  -webkit-text-stroke: min(0.15vw, 3px) #000000; 
  text-stroke: min(0.15vw, 3px) #000000;
}

.t-border-black-sm {
  -webkit-text-stroke: min(0.05vw, 2px) #000000; 
  text-stroke: min(0.05vw, 2px) #000000;
}

.t-shadow25 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.t-out-shadow{
  text-shadow: 
    -1px -1px 1px #000,
    1px -1px 1px #000,
    -1px 1px 1px #000,
    1px 1px 1px #000,

    -2px -2px 2px #000,
    2px -2px 2px #000,
    -2px 2px 2px #000,
    2px 2px 2px #000,

    0px 0px 1px #000;

}

.t-blue {
  color: #5DB7F8;
}

.t-green {
  color: #5BCE6C;
}

.t-purple {
  color: #996DE3;
}

.t-redorange {
  color: #EB665D;
}

img.bg {
  position: static;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* background-size: cover; */
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transi-ease-in-out {
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -ms-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;
}

.opacity-ease-in-out {
  -webkit-transition: opacity 0.75s ease-in-out;
  -moz-transition: opacity 0.75s ease-in-out;
  -ms-transition: opacity 0.75s ease-in-out;
  -o-transition: opacity 0.75s ease-in-out;
  transition: opacity 0.75s ease-in-out;
}


.transi-fade-in {

}

.ani_fade_in {
  animation: 2s ease-out 0s 1 ani_fadeIn;
  flex: auto;
  /* background-color: red; */
}

@keyframes ani_fadeIn {
  0% {
    transform: translateX(0%); opacity: 0;
  }
  100% {
    transform: translateX(0); opacity: 1;
  }
}

@keyframes ani_fadeOut {
  0% {
    transform: translateX(0%); opacity: 1;
  }
  100% {
    transform: translateX(0); opacity: 0;
  }
}