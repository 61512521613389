$layout-color: false;

@mixin test-layout($color){
    @if $layout-color {
        background-color: $color;
    }
}

.evchap1 {
    font-family: 'Fugaz One', sans-serif;

    .img-bg {
        height: 100vh !important;
        width: 100vw !important;
        object-fit: cover;
    }

    .context {
        @include test-layout(#ff000044);
        position: absolute;
        overflow: hidden !important;
        top: 0px;
        left: 0px;
        width: 100vw !important;
        height: 100vh !important;
        
    }

    .layer1 {
        position: relative;
        @include test-layout(#00ff0044);
        height: 100% !important;
        width: 75% !important;

        .layer1-1 {
            /* background-color: #ffffff44; */
            position: relative;
            height: 60%;

            .sliderbillboard {
                @include test-layout(#ff00ff44);
            }
        }

        .layer1-2 {
            @include test-layout(#FF000044);
            position: relative;
            /* height: 40%; */
        }
    }

    .layer2 {
        /* @include test-layout(#00ffff44); */
        height: 100% !important;
        width: 25% !important;
    }

    .layer-logo {
        position: absolute;
        /* width: 100%; */
        height: 25% !important;
        left: 50%;
        transform: translate(-50%, 10%);
        font-size: 12px;
    }

    .page2 {
        position: relative;
        @include test-layout(#0000ff44);

        .header-area {
            visibility: hidden;
            text-shadow: 
                -1px -1px 0 black, /* Top-left shadow */
                1px -1px 0 black,  /* Top-right shadow */
                -1px 1px 0 black,  /* Bottom-left shadow */
                1px 1px 0 black;   /* Bottom-right shadow */
            font-family: 'Fugaz One', sans-serif;
            font-size: 24px;
            font-weight: 400;

            .highlight {
                color: #FADE00;
            }
        }

        .header-area.showed {
            visibility: visible;
        }

        .zombie {
            transform: scale(1);
            filter: grayscale(100%) brightness(80%);
            transition: transform 0.3s ease-in-out;

            &.targeted{
                filter: grayscale(0%) brightness(100%);
            }

            &.shooted {
                transform: scale(0.65);
                transform-origin: bottom;
                filter: grayscale(0%) brightness(0%);
                animation: smaller65 0.3s ease-in-out;
            }

            &.unshoot {
                padding-bottom: 15%;
                transform: scale(0.50);
                transform-origin: bottom;
                filter: grayscale(100%) brightness(100%);
                animation: smaller65 0.3s ease-in-out;
            }
        }

        .target {
            position: relative;
        }

        .reward {
            position: absolute;
            color: #818181;
            height: 30% !important;
            transition: visibility 0.3 ease;
            text-shadow: 
                -1px -1px 0 black, /* Top-left shadow */
                1px -1px 0 black,  /* Top-right shadow */
                -1px 1px 0 black,  /* Bottom-left shadow */
                1px 1px 0 black;   /* Bottom-right shadow */
            font-family: 'Fugaz One', sans-serif;
            font-size: 10px;
            font-weight: 400;
            transform-origin: bottom;
            animation: bigger35 0.3s ease-in-out;

            .highlight {
                color: #FADE00;
                font-size: 14px;
            }
        }

        .crosshair {
            position: absolute;
            top: 35%;
            left: 35%;
        
        }
    }

    
}

.modal-quest {
    .quest-header {
        .header1 {
            font-size: 18px;
        }

        .header2 {
            font-size: 10px;
            color: #939393;
        }
    }

    .quest-context {
        border: solid 1px #363636;

        .done {
            color: #00FF38;
        }
    }
}

@keyframes smaller65 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.65);
    }
}

@keyframes bigger35 {
    0% {
        transform: scale(0.35);
    }
    100% {
        transform: scale(1);
    }
}