.home .home3{
    background-color: #000000;
}

.home .home3 .image-gallery-slide-wrapper {
    position: relative;
}

.home .home3 .image-gallery-left-nav {
    position: absolute;
    width: 80px;
    height: auto;
    cursor: pointer;
    z-index: 10;
    top: calc(100% - 80px);
    left: calc(50% - 90px);
}

.home .home3 .image-gallery-right-nav {
    position: absolute;
    width: 80px;
    height: auto;
    cursor: pointer;
    z-index: 10;
    top: calc(100% - 80px);
    right: calc(50% - 90px);
}

.home .home3 .image-gallery .item {
    position: relative;
    width: 100%;
    height: auto;
    
    /* background-color: blue; */

}

.home .home3 .image-gallery .item .map {
    /* background-color: blue; */
    position: relative;
    max-width: min(75vw, 1424px);
}

.home .home3 .image-gallery .item .map img {
    object-fit:cover;
    height: 100%;
}

.home .home3 .image-gallery .item .content {
    position: absolute;
    
    top: 0px;
    right: 0px;
    max-width: 55%;
    width: 50%;
    min-width: 45%;
    height: 100%;
    z-index: 3;
    padding-left: 10% !important;
    /* background: linear-gradient(270deg, #180013 36.94%, rgba(0, 0, 0, 0) 53.89%) !important; */
    /* background: linear-gradient(to right, #18001300 0%, #180013 20% , #180013 100%); */
    background: linear-gradient(to right, #00000000 0%, #000000 20% , #000000 100%);
    
    /* background-color: black; */


}

.home .home3 .image-gallery .item .content-inner {
    position: relative;
    align-items: start;
    font-family: 'IBM Plex Sans Thai', sans-serif;
    font-weight: 400;
    font-size: max(min(1.2vw, 20px), 16px);
    line-height: max(min(2.1vw, 36px), 28px);
    /* letter-spacing: 0em !important; */
    width: 85%;
}

.home .home3 .image-gallery .item .content-context {
    position: relative;
    width: 100%;
    align-items: start;
    text-align: start;
}

.home .home3 .image-gallery .item .enemies {
    max-width: 100%;
    /* background-color: red; */
}

.home .home3 .image-gallery .item .enemy {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

@media (max-width: 992px) {
    .home .home3 .image-gallery .item .map {
        /* background-color: blue; */
        position: relative;
        width: 100%;
        max-width: 100%;
    }

    .home .home3 .image-gallery .item .content {
        position: relative;
        max-width: 100%;
        width: 100%;
        min-width: 100%;
        height: auto;
        z-index: 3;
        padding-left: 0% !important;
        margin-top: 2vw;
        background: transparent;

    }

    .home .home3 .image-gallery .item .content-inner {
        align-items: center;
        align-content: center;
        text-align: center;
    }

    .home .home3 .image-gallery-left-nav {
        width: max(min(10vw, 80px), 40px);
        left: calc(50% - max(min(10vw, 80px), 40px) - 1vw);
        top: 50vw;
    }

    .home .home3 .image-gallery-right-nav {
        width: max(min(10vw, 80px), 40px);
        right: calc(50% - max(min(10vw, 80px), 40px) - 1vw);
        top: 50vw;
    }
}

.home .home3 .image-gallery-thumbnail img {
    object-fit: cover;
    width: 100%;
    height: auto;
}

.home .home3 .image-gallery-thumbnails-container{
    background-color: black;
    padding-top: max(min(1vw, 10px), 2px);
    padding-bottom: max(min(1vw, 10px), 2px);
}


.home .home3 .image-gallery-thumbnail {
    margin-left: max(min(1vw, 10px), 2px);
    padding: 0px;
    min-width: 192px;
    width: 18.18vw;
    max-width: 320px;
    height: auto;
    
}

@media (max-width: 576px) {
    .home .home3 .image-gallery-thumbnail {
        min-width: 128px;
        width: 40vw;
        max-width: 240px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .home .home3 .image-gallery-thumbnail {
        min-width: 192px;
        width: 28.57vw;
        max-width: 240px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .home .home3 .image-gallery-thumbnail {
        min-width: 192px;
        width: 22.22vw;
        max-width: 320px;
    }
}